<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "ojt-evaluation-management-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="currentRouteName == 'ojt-evaluation-management-update'"
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['ojt-evaluation-list'])">
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>

                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="currentRouteName == 'ojt-evaluation-management-update'"
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['ojt-evaluation-list'])">
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>

                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="currentRouteName == 'ojt-evaluation-management-update'"
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedLevel.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.selectedLevel.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['ojt-evaluation-list'])">
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>

                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="currentRouteName == 'ojt-evaluation-management-update'"
                        :options="employeeLists"
                        :selectable="(options) => isSelectable(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['ojt-evaluation-list'])">
                      <v-select
                        v-model="selectedEmployee"
                        label="employee_name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>

                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="ojt.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-3 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="ojt.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Assessors</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(assessor, index) in assessors"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">
                    {{ assessor.assessor_employee_name }}
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      class="form-control mt-2"
                      v-model="assessor.total_score"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-4" v-if="index == 0">
                  <div class="mt-2" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                    <input
                      type="text"
                      class="form-control mt-2"
                      placeholder="Remark"
                      aria-label="Remark"
                      v-model="remark"
                    />
                  </div>

                  <div class="mt-2" v-else-if="hasPermissions(['ojt-evaluation-list'])">
                    <input
                      type="text"
                      class="form-control mt-2"
                      placeholder="Remark"
                      aria-label="Remark"
                      disabled
                      v-model="remark"
                    />
                  </div>

                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4 mt-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="totalScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4 mt-4">Average Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="averageScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4 mt-4">point</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="percentage"
                    disabled
                  />
                  <small class="text-success" v-if="point != 0"
                    >Calculate point - {{ point }}</small
                  >
                </div>
                <div class="col-sm-4" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                  <p class="checkClick" @click="showCheck()">Check</p>
                </div>
              </div>

              <div class="row g-3 pt-2">
                <div class="col-sm-4">Align with Program</div>
                <div class="col-sm-4" v-if="hasPermissions(['orientation-evaluation-create', 'orientation-evaluation-edit'])">
                  <v-select
                    v-model="selectedAlign"
                    class=""
                    label="name"
                    :options="[
                      { val: 1, name: 'Yes' },
                      { val: 0, name: 'No' },
                    ]"
                    :clearable="false"
                  >
                  </v-select>
                </div>

                <div class="col-sm-4" v-else-if="hasPermissions(['orientation-evaluation-list'])">
                  <v-select
                    v-model="selectedAlign"
                    class=""
                    label="name"
                    disabled
                    :options="[
                      { val: 1, name: 'Yes' },
                      { val: 0, name: 'No' },
                    ]"
                    :clearable="false"
                  >
                  </v-select>
                </div>

              </div>


              <div class="row" v-if="hasPermissions(['ojt-evaluation-create', 'ojt-evaluation-edit'])">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'ojt-evaluation-management' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="assessors.length == 0 || percentage == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      :title="assessors.length == 0 ? 'There is no assessors.' : 'You need to check your point first.'"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-"
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>

                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'ojt-evaluation-management-update'
                          ? updateOjtEvaluation()
                          : createOjtEvaluation()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      assessors: [],
      programs: [],
      levels: [],
      batch: [],
      existedEmployee: [],
      employeeLists: [],
      percentage: 0,
      totalScore: 0,
      averageScore: 0,
      point: 0,
      remark: "",
      ojt: {
        type: "OJT",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        employee_id: "",
        level: "",
      },
      selectedAlign: null,
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      selectedLevel: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      selectedLevel: { required },
      ojt: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        // available: { required },
        level: {
          required: requiredIf(() => {
            return this.ojt.type != "Orientation";
          }),
        },
      },
    };
  },
  methods: {
    showCheck() {
      if (this.assessors.length == 0) return;
      this.point = this.assessors[0].point;
      this.percentage = Math.round(
        (this.averageScore / this.assessors[0].total_height_score) *
          (this.point / 100) *
          100
      );
    },
    //  isSelectable(options) {
    //   // Check if the options' ID is present in the usedBatch array
    //   return (
    //     this.usedBatch.find((batch) => batch.id === options.id) === undefined
    //   );
    // },
    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async createOjtEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || this.assessors.length == 0) return;

      this.isLoading = true;
      const toSendData = {
        percentage: this.percentage,
        remark: this.remark,
        trainer_assessment_id: this.assessors.map(
          (item) => item.id
        ),
        align_with_program: this.selectedAlign.val,

      };

      // console.log(toSendData);
      await axios
        .post(`${this.baseUrl}admin/v2/trainer-evaluation`, toSendData, {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then(() => {
          this.$router.push({ name: "ojt-evaluation-management" });
          this.toast.success("Successfully created ojt Evaluation");
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          this.isLoading = false;
        });
    },
    async updateOjtEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || this.assessors.length == 0) return;

      this.isLoading = true;
      const toSendData = {
        percentage: this.percentage,
        remark: this.remark,
        trainer_assessment_id: this.assessors.map(
          (item) => item.id
        ),
        align_with_program: this.selectedAlign.val,

      };
      await axios
        .put(
          `${this.baseUrl}admin/v2/trainer-evaluation/${this.$route.params.id}`,
          toSendData,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "ojt-evaluation-management" });
          this.toast.success("Successfully created ojt Evaluation");
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          this.isLoading = false;
        });
    },
    async getAllAssessors(program_id, batch_id, level, employee_id) {
      this.loading = true;
      this.$Progress.start();
      // let totalHighestScores = 0;
      await axios
        .get(
          `${this.baseUrl}admin/v2/get-evaluation-assessor-list/${program_id}/${batch_id}/${employee_id}/OJT?level=${level}`
        )
        .then((response) => {
          if (response.data.data.length != 0) {
            this.assessors = response.data.data;
            this.assessors.forEach((assessor) => {
              this.totalScore += assessor.total_score;
            });

            this.averageScore = this.totalScore / this.assessors.length;
            if (this.currentRouteName == "ojt-evaluation-management-update") {
              this.point = this.assessors[0].point;
            }
          }
          // console.log(response.data);
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("No assessors for this employees.");
          this.loading = false;
          this.$Progress.finish();
        });
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedLevel = null;
        this.levels = [];
        this.selectedBatch = null;
        this.clearData();
        this.ojt.program_id = this.selectedProgram.id;
        this.ojt.program_name = this.selectedProgram.name;
        this.ojt.program_short_name = this.selectedProgram.short_name;
        // this.getAllCriterias(this.ojt.program_id);
        this.getBatchByProgram(this.ojt.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.selectedLevel = null;
        this.levels = [];
        this.clearData();
        this.ojt.program_batch_id = this.selectedBatch.id;
        this.ojt.program_batch_no = this.selectedBatch.batch_no;
        // this.ojt.available = this.selectedBatch.available;
        this.getAllLevels();
        // this.getEmployeeByBatch(this.ojt.program_batch_id);
      }
    },

    getSelectedLevel() {
      if (this.selectedLevel) {
        this.clearData();
        this.ojt.level = this.selectedLevel.name;
        // console.log(this.selectedLevel + " and " + this.ojt.level);
        // console.log(this.ojt.program_batch_id);
        this.getEmployeeByBatch(this.ojt.program_batch_id);
      }
    },

    getSelectedEmployee() {
      this.selectedpoint = null;
      this.point = 0;
      this.percentage = 0;
      this.assessors = [];
      this.averageScore = 0;
      this.totalScore = 0;
      this.remark = "";
      if (this.selectedEmployee) {
        this.ojt.employee_id = this.selectedEmployee.employee_id;
        this.ojt.employee_name = this.selectedEmployee.employee_name;
        this.ojt.join_date = this.selectedBatch.join_date;

        this.getAllAssessors(
          this.ojt.program_id,
          this.ojt.program_batch_id,
          this.ojt.level,
          this.ojt.employee_id
        );
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.ojt.employee_id = "";
      this.ojt.employee_name = "";
      this.ojt.join_date = "";
      this.selectedpoint = null;
      this.assessors = [];
      this.totalScore = 0;
      this.percentage = 0;
      this.assessors = [];
      this.averageScore = 0;
      this.point = 0;
      this.remark = "";
    },

    updateTotalScore() {
      let totalScore = 0;
      for (let i = 0; i < this.assessors.length; i++) {
        totalScore += this.assessors[i].selectedScore;
      }
      this.totalScore = totalScore;
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program_id}`)
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(
          `${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data.employee_ids.map(
            (item) => {
              return {
                employee_name: item.employee.name,
                employee_id: item.employee.emp_id,
              };
            }
          );
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },

    async getojtAssessmentById() {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(`${this.baseUrl}admin/v2/trainer-evaluation/${this.$route.params.id}`)
        .then((response) => {
          const data = response.data.data;
          // this.ojt.program_id = data.program_id;
          console.log(data.employee_id);

          this.selectedProgram = {
            id: data.program_id,
            name: data.program_name,
          };
          this.selectedBatch = {
            id: data.batch_id      ,
            batch_no: data.program_batch_no,
            // available: data.available,
          };
          this.selectedEmployee = {
            employee_id: data.employee_id,
            employee_name: data.employee_name,
            join_date: data.join_date,
          };
          this.selectedLevel = data.level;
          this.ojt = data;
          this.ojt.employee_id = data.employee_id;
          this.remark = data.trainer_evaluation.remark;
          this.percentage = data.trainer_evaluation.percentage;
          this.selectedAlign = data.trainer_evaluation.align_with_program == 0 ? { val: 0 , name: 'No'} : { val: 1, name: 'Yes'};


          this.getBatchByProgram(this.ojt.program_id);
          this.getEmployeeByBatch(this.ojt.batch_id);
          this.getAllLevels();
          this.getAllAssessors(
            data.program_id,
            data.batch_id,
            data.level,
            data.employee_id
          );

          // this.loading = false;
          // this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getExistedEmployees() {
      await axios
      .get(`${this.baseUrl}admin/v2/trainer-evaluation?type=OJT`)
        .then((response) => {
          this.existedEmployee = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
    },
    isSelectableProgram(options) {
      if(!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id
    },
    isSelectableBatch(options) {
      if(!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if(!this.selectedLevel || !options) return true;
      return this.selectedLevel.id!= options.id;
    },
     isSelectable(options) {
      // console.log(options)
      if (this.selectedEmployee) {
                const empExist = this.existedEmployee.some((item) => item.employee_id == this.selectedEmployee.employee_id && item.level == this.selectedLevel.name)
                // If the batch doesn't exist, add it to existedEmployee
                if (!empExist) {
                    this.existedEmployee = this.existedEmployee.filter(
                        (item) => !item.is_selected
                    );
                    this.existedEmployee.push({
                      employee_id: this.selectedEmployee.employee_id,
                      level: this.selectedLevel.name,
                      is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in existedEmployee
            return !this.existedEmployee.find(
                (item) => item.employee_id == options.employee_id && item.level == this.selectedLevel.name
            );
    },
    // isSelectable(options) {
    //   // console.log(options)
    //   return (
    //     this.existedEmployee.find(
    //       (employee) =>
    //         employee.employee_id === options.employee_id &&
    //         employee.level == this.ojt.level
    //     ) === undefined
    //   );
    // },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "ojt-evaluation-management-update") {
      await this.getojtAssessmentById();
    }else{
      this.selectedAlign = { val: 1, name: "Yes" };

    }
    await this.getPrograms();
    await this.getExistedEmployees();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.scoreBox {
  width: 262px;
  margin-left: 12px;
}
.checkClick {
  cursor: pointer;
  font-weight: bold;
  margin-top: 13px;
  user-select: none;
  font-size: 1.1em;
}
.checkClick:hover {
  font-weight: 300;
}
</style>
